import { useRef, useEffect, useState } from "react";
import XbuttonIcon from "../assets/xButtonIcon.svg";
import { openLink } from "../services/openLink";
import CheckMarkIcon from "../assets/checkMarkIcon.svg";
import { useGetQualifiedModalStore } from "../stores/getQualifiedStore";

const GetQualifiedModal = () => {
	const modalRef = useRef<HTMLDivElement | null>(null);
	const { setShowGetQualifiedModal } = useGetQualifiedModalStore();
	const [buttonDisabled, setButtonDisabled] = useState(false);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				setShowGetQualifiedModal(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [setShowGetQualifiedModal]);

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center px-2">
			<div className="absolute inset-0 bg-white bg-opacity-70 backdrop-blur-sm" />
			<div
				ref={modalRef}
				className="relative w-full max-w-md py-6 px-6 bg-white shadow-md rounded-[20px] space-y-4 z-10  flex flex-col items-center justify-center"
			>
				<button
					type="button"
					className="right-4 absolute w-[46px] h-[46px] rounded-full flex items-start justify-end top-4"
					onClick={() => setShowGetQualifiedModal(false)}
				>
					<img src={XbuttonIcon} alt="Close" />
				</button>
				<div className="w-full clear-start space-y-8">
					<div className="font-cormorant-garamond leading-none text-[36px] font-bold tracking-[-0.9px]">
						Before you search, see how much Zown can boost your budget.
					</div>
					<div className="space-y-2">
						<div className="flex gap-2">
							<img src={CheckMarkIcon} alt="" />
							No credit check
						</div>
						<div className="flex gap-2">
							<img src={CheckMarkIcon} alt="" />
							No repayments required
						</div>

						<div className="flex gap-2">
							<img src={CheckMarkIcon} alt="" />
							Qualify for up to $25,000
						</div>

						<div className="flex gap-2">
							<img src={CheckMarkIcon} alt="" />
							Buy any home on the market
						</div>

						<div className="flex gap-2">
							<img src={CheckMarkIcon} alt="" />
							We don't take any ownership
						</div>
					</div>
					<button
						type="button"
						className="font-bold text-lg rounded-full disabled:bg-[#D9D9D9] text-white bg-[#1A1A1A] w-full py-3.5"
						disabled={buttonDisabled}
						onClick={() => {
							setButtonDisabled(true);
							window
								._sendEvent("ZownCaBuyerSearchPopupQualify", {})
								.catch((error: unknown) => {
									console.error(error);
								})
								.finally(() => {
									setButtonDisabled(false);
									openLink("https://zown.ca/buyer/down-payment-qualification");
								});
						}}
					>
						Get Qualified
					</button>
				</div>
			</div>
		</div>
	);
};

export { GetQualifiedModal };
