import { create } from "zustand";
import { persist } from "zustand/middleware";

interface GetQualifiedModalStoreState {
  showGetQualifiedModal: boolean;
  seenGetQualifiedModal: boolean;
  setShowGetQualifiedModal: (show: boolean) => void;
  setSeenGetQualifiedModal: (seen: boolean) => void;
}

// Persist only seenGetQualifiedModal
const useSeenGetQualifiedModalStore = create<{
  seenGetQualifiedModal: boolean;
  setSeenGetQualifiedModal: (seen: boolean) => void;
}>()(
  persist(
    (set) => ({
      seenGetQualifiedModal: false,
      setSeenGetQualifiedModal: (seen) => set({ seenGetQualifiedModal: seen }),
    }),
    {
      name: "seen-get-qualified-modal-storage", // Stores only seenGetQualifiedModal
    }
  )
);

// Normal Zustand store (not persisted)
const useGetQualifiedModalStore = create<GetQualifiedModalStoreState>(
  (set) => ({
    showGetQualifiedModal: false,
    setShowGetQualifiedModal: (show) => set({ showGetQualifiedModal: show }),
    ...useSeenGetQualifiedModalStore.getState(), // Include the persisted state
  })
);

export { useGetQualifiedModalStore, useSeenGetQualifiedModalStore };
