import { useNavigate } from "react-router-dom";
import ClockIcon from "../../assets/clockIcon.svg";
import { updateAccountData } from "../../services/account";
import { PhoneValidator } from "../../utils/phoneValidator";
import { useUserProfileStore } from "../../stores/profileStore";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import { useEffect, useRef, useState, type ChangeEvent } from "react";
import { AddressLookupInput } from "../common/AddressLookup/addressLookupInput";
import {
	addressLookupSearchQuery,
	getAddressType,
} from "../../services/addressLookup";
import {
	useAddressLookupStore,
	type AddressSuggestion,
} from "../../stores/addressLookupStore";

const DetailsConfirmationView = () => {
	// const { pendingContracts, setPendingContracts } = useContractStore();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState(formatPhoneNumber(""));
	const [firstNameError, setFirstNameError] = useState("");
	const [lastNameError, setLastNameError] = useState("");
	const [phoneNumberError, setPhoneNumberError] = useState("");
	const [addressError, setAddressError] = useState("");
	const [disabledButton, setDisabledButton] = useState(false);
	const [agreementChecked, setAgreementChecked] = useState(false);
	const [agreementError, setAgreementError] = useState("");
	const { userProfile, setUserProfile } = useUserProfileStore();
	const suggestionRef = useRef<AddressSuggestion | null>(null);

	const { selectedAddress, setSelectedAddress, setSuggestion } =
		useAddressLookupStore();

	const navigate = useNavigate();

	useEffect(() => {
		setFirstName(userProfile?.firstName ?? "");
		setLastName(userProfile?.lastName ?? "");
		setPhoneNumber(formatPhoneNumber(userProfile?.phoneNumber ?? ""));
		setSelectedAddress(userProfile?.address?.address1 ?? "");
	}, [userProfile, setSelectedAddress]);

	const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPhoneNumber(formatPhoneNumber(e.target.value));
	};

	const validateInputs = async () => {
		let isValid = true;

		if (!firstName.trim()) {
			setFirstNameError("First name cannot be empty.");
			isValid = false;
		} else {
			setFirstNameError("");
		}

		if (!lastName.trim()) {
			setLastNameError("Last name cannot be empty.");
			isValid = false;
		} else {
			setLastNameError("");
		}

		if (selectedAddress) {
			const response = await addressLookupSearchQuery(selectedAddress);
			if (response?.valid_address && response.Items?.length > 0) {
				const selectedSuggestion = response.Items[0];
				suggestionRef.current = selectedSuggestion;
				setSuggestion(selectedSuggestion);
				setAddressError("");
			} else {
				setAddressError("Invalid address.");
				isValid = false;
			}
		} else {
			setAddressError("Address cannot be empty.");
			isValid = false;
		}

		if (agreementChecked) {
			setAgreementError("");
		} else {
			setAgreementError(
				"You must agree that you are not under contract with another real estate agent.",
			);
			isValid = false;
		}

		let phoneIsValid = false;
		try {
			if (phoneNumber.trim()) {
				if (PhoneValidator.isValid(phoneNumber, "CA")) {
					phoneIsValid = true;
					setPhoneNumberError("");
				} else if (PhoneValidator.isValid(phoneNumber, "US")) {
					phoneIsValid = true;
					setPhoneNumberError("");
				} else {
					throw new Error("Invalid phone number format");
				}
			} else {
				setPhoneNumberError("Phone number cannot be empty.");
			}
		} catch (error) {
			setPhoneNumberError("Invalid phone number.");
			isValid = false;
		}

		if (!phoneIsValid) {
			isValid = false;
		}

		return isValid;
	};

	const handleConfirmDetails = async () => {
		setDisabledButton(true);
		const isInputsValid = await validateInputs();

		const currentSuggestion = suggestionRef.current;

		if (isInputsValid && currentSuggestion && currentSuggestion.Id) {
			try {
				const addressResult: any[] = (
					await getAddressType(currentSuggestion.Id)
				).Items;
				const addressObj = addressResult.find(
					(item: any) => item.Language === "ENG",
				);

				let formattedPhoneNumber: string | undefined;
				try {
					if (PhoneValidator.isValid(phoneNumber, "CA")) {
						formattedPhoneNumber = PhoneValidator.formatE164(phoneNumber, "CA");
					} else if (PhoneValidator.isValid(phoneNumber, "US")) {
						formattedPhoneNumber = PhoneValidator.formatE164(phoneNumber, "US");
					}
				} catch {
					formattedPhoneNumber = "";
				}

				const response = await updateAccountData({
					phoneNumber: formattedPhoneNumber,
					firstName: capitalizeFirstLetter(firstName),
					lastName: capitalizeFirstLetter(lastName),
					address: {
						address1: addressObj.Line1,
						address2: addressObj.Line2,
						city: addressObj.City,
						state: addressObj.Province,
						postalCode: addressObj.PostalCode,
						country: addressObj.CountryIso2,
					},
				});

				setUserProfile(response);
				window
					._sendEvent("ZownAppBuyerBookShowingConfirmDetails", {})
					.catch((error: unknown) => {
						console.error(error);
					})
					.finally(() => {
						navigate("/cart/contract");
					});
			} catch (error) {
				console.error("Failed to update account data", error);
			} finally {
				setDisabledButton(false);
			}
		} else {
			setAddressError("Please select a valid address.");
			setDisabledButton(false);
		}
	};

	return (
		<div className="flex flex-col items-center text-black space-y-6 w-full pb-6 pt-2">
			<div className="text-center space-y-2 text-black px-4">
				<div className="text-[22px] font-semibold">Confirm your details</div>
				<div className="text-sm text-black">
					Confirm whether the information we have is correct. This information
					will be used for your showings contract.
				</div>
			</div>

			<div className="w-full space-y-4 px-4">
				<div className="flex flex-col items-start space-y-2">
					<div className="text-z-black font-semibold text-sm">
						Legal First Name
					</div>
					<input
						type="text"
						spellCheck="false"
						autoComplete="off"
						className="w-full rounded-[4.28px] px-2 py-3 bg-[#F9F9F9]"
						value={firstName}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setFirstName(e.target.value)
						}
					/>
					{firstNameError && (
						<div className="text-red-500 text-sm">{firstNameError}</div>
					)}
				</div>

				<div className="flex flex-col items-start space-y-2">
					<div className="text-black font-semibold text-sm">
						Legal Last Name
					</div>
					<input
						type="text"
						spellCheck="false"
						autoComplete="off"
						className="w-full rounded-[4.28px] px-2 py-3 bg-[#F9F9F9]"
						value={lastName}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setLastName(e.target.value)
						}
					/>
					{lastNameError && (
						<div className="text-red-500 text-sm">{lastNameError}</div>
					)}
				</div>

				<div className="flex flex-col items-start space-y-2">
					<div className="text-black font-semibold text-sm">Phone Number</div>
					<input
						type="tel"
						spellCheck="false"
						autoComplete="off"
						className="w-full rounded-[4.28px] px-2 py-3 bg-[#F9F9F9]"
						value={phoneNumber}
						onChange={handlePhoneNumberChange}
					/>
					{phoneNumberError && (
						<div className="text-red-500 text-sm">{phoneNumberError}</div>
					)}
				</div>
				<div className="flex flex-col items-start space-y-2">
					<div className="text-black font-semibold text-sm">Address</div>
					<AddressLookupInput error={addressError} />
				</div>
				<div className="flex items-center h-full w-full">
					<input
						id="default-checkbox"
						type="checkbox"
						checked={agreementChecked}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setAgreementChecked(e.target.checked)
						}
						className="w-[30px] h-[30px] text-z-dark-moss-green bg-[#F9F9F9] rounded-[8px] border-[#A4A4A4]"
					/>
					<label
						htmlFor="default-checkbox"
						className="ms-2 text-sm font-medium h-full text-[#32302F]"
					>
						I agree I'm not under contract or working with another real estate
						agent
					</label>
				</div>
				{agreementError && (
					<div className="text-red-500 text-sm">{agreementError}</div>
				)}
			</div>

			<div className="w-full">
				<hr className="h-px my-4 bg-[#EDF1F4] border-0" />
				<div className="px-4">
					<button
						type="button"
						className="bg-z-dark-moss-green w-full font-semibold text-[20px] disabled:bg-[#D9D9D9] text-white rounded-full py-3.5 shadow-md drop-shadow-md flex items-center justify-center gap-3"
						onClick={handleConfirmDetails}
						disabled={disabledButton}
					>
						<img src={ClockIcon} alt="Clock Icon" />
						Confirm my details
					</button>
				</div>
			</div>
		</div>
	);
};

export { DetailsConfirmationView };
