import type { JSX } from "react";
import { useAuth } from "../context/authProvider";
import { useNavbarStore } from "../stores/navBarStatus";
import { LogOutModal } from "../components/logOutModal";
import { useFinanceStore } from "../stores/financeStore";
import { useStep } from "../stores/buyerHomeFeaturesStore";
import { useFilterPanelStore } from "../stores/filterStore";
import { useUserProfileStore } from "../stores/profileStore";
import { useBottomBarStore } from "../stores/bottomBarStore";
import { CartTopBar } from "../components/TopBars/cartTopBar";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import { CartModal } from "../components/Finances/Modal/cartModal";
import { NavigationBar } from "../components/common/navigationBar";
import { ProfileTopBar } from "../components/TopBars/profileTopBar";
import { FinanceTopBar } from "../components/TopBars/financeTopBar";
import { WatchVideo } from "../components/Finances/Modal/watchVideo";
import { useLogOutModalStore } from "../stores/showLogOutModalStore";
// import { MainViewTopBar } from "../components/TopBars/mainViewTopBar";
import { FlinksModal } from "../components/Finances/Modal/flinksModal";
import { YourTopMatchesModal } from "../components/yourTopMatchesModal";
import { FinancialReport } from "../components/Finances/financialReport";
import { WhenBuyHome } from "../components/Finances/OverPage/whenBuyHome";
import { FilterPanel } from "../components/common/FilterPanel/filterPanel";
import { DebtOverPage } from "../components/Finances/OverPage/debtOverPage";
import { PlanSpendHome } from "../components/Finances/OverPage/planSpendHome";
import { useEffect, useRef, useState, useCallback, cloneElement } from "react";
import { PlanBuyingHome } from "../components/Finances/OverPage/planBuyingHome";
import { useShowTopMatchesModalStore } from "../stores/showTopMatchesModalStore";
import { BuyHomePlanModal } from "../components/Finances/Modal/buyHomePlanModal";
import { FinanceIncomeModal } from "../components/Finances/Modal/financeIncomeModal";
import { FinanceSavingsModal } from "../components/Finances/Modal/financeSavingsModal";
import { AnotherDebtOverPage } from "../components/Finances/OverPage/anotherDebtOverPage";
import { StartHomeShoppingModal } from "../components/Finances/Modal/startHomeShoppingModal";
import { EditHouseGoalOverPage } from "../components/Finances/OverPage/editHouseGoalOverPage";
import { ConnectBankAccountModal } from "../components/Finances/Modal/connectBankAccountModal";
import { PaymentAssistanceInfoModal } from "../components/Finances/Modal/paymentAssistanceInfoModal";
import { GetQualifiedModal } from "../components/getQualifiedModal";
import { useGetQualifiedModalStore } from "../stores/getQualifiedStore";

const topBarMapping = (
	setTopBarHeight: (height: number) => void,
): { [key: string]: JSX.Element } => ({
	// "/": <MainViewTopBar setTopBarHeight={setTopBarHeight} />,
	"/cart": <CartTopBar setTopBarHeight={setTopBarHeight} />,
	"/cart/comparison": <CartTopBar setTopBarHeight={setTopBarHeight} />,
	"/profile": (
		<ProfileTopBar
			setTopBarHeight={setTopBarHeight}
			title="Profile"
			showNotification={true}
			showChat={true}
		/>
	),
	"/finances": <FinanceTopBar setTopBarHeight={setTopBarHeight} />,
});

const MainLayout = () => {
	const bottomBarRef = useRef<HTMLDivElement | null>(null);
	const { bottomBarHeight, setBottomBarHeight } = useBottomBarStore();
	const { showLogOutModal } = useLogOutModalStore();
	const { showTopMatchesModal } = useShowTopMatchesModalStore();
	const { isNavbarVisible } = useNavbarStore();
	const [topBarHeight, setTopBarHeight] = useState(0);
	const matches = useMatches();
	const currentRoute = matches[matches.length - 1] || null;
	const { setTotalDebt } = useFinanceStore();
	const { setCurrentStep } = useStep();
	const { userProfile } = useUserProfileStore();
	const navigate = useNavigate();
	const { isWebview } = useAuth();
	const { showGetQualifiedModal } = useGetQualifiedModalStore();

	const { filterPanelVisible } = useFilterPanelStore();

	useEffect(() => {
		if (!userProfile) {
			return;
		}

		const missingEssentialDetails = !(
			userProfile.firstName &&
			userProfile.lastName &&
			userProfile.phoneNumber
		);

		if (missingEssentialDetails) {
			setCurrentStep(0);
			navigate("/buyer-input-flow/details-input");
			return;
		}
		const incomeAmount = userProfile.finances?.income?.[0]?.amount || 0;
		const savingsAmount = userProfile.finances?.savings?.[0]?.amount || 0;
		const monthlyDebtAmount =
			userProfile.finances?.monthlyDebt?.[0]?.amount || 0;

		if (incomeAmount === 0) {
			setCurrentStep(1);
			navigate("/buyer-input-flow/annual-income");
			return;
		}

		if (savingsAmount === 0) {
			setCurrentStep(2);
			navigate("/buyer-input-flow/saving");
			return;
		}

		if (monthlyDebtAmount === 0) {
			setCurrentStep(3);
			navigate("/buyer-input-flow/monthly-debt");
			return;
		}
	}, [userProfile, setCurrentStep, navigate]);

	const updateBottomBarHeight = useCallback(() => {
		if (bottomBarRef.current) {
			setBottomBarHeight(bottomBarRef.current.offsetHeight + 16);
		}
	}, [setBottomBarHeight]);

	useEffect(() => {
		updateBottomBarHeight();
		window.addEventListener("resize", updateBottomBarHeight);
		return () => {
			window.removeEventListener("resize", updateBottomBarHeight);
		};
	}, [updateBottomBarHeight]);

	useEffect(() => {
		if (!topBarMapping(setTopBarHeight)[currentRoute?.pathname]) {
			setTopBarHeight(0);
		}
	}, [currentRoute]);

	const renderTopBar = () => {
		const topBar = topBarMapping(setTopBarHeight)[currentRoute?.pathname];
		return topBar ? cloneElement(topBar, { setTopBarHeight }) : null;
	};

	return (
		<div
			className={`absolute inset-0 h-full overflow-hidden flex flex-col justify-center items-center  ${
				currentRoute?.pathname === "/profile" ? "bg-[#F5F5F5]" : "bg-white"
			}`}
		>
			{renderTopBar()}
			{/*    <div
        className={`relative text-white ${
          currentRoute?.pathname === "/profile" ? "bg-[#F5F5F5]" : "bg-white"
        } overflow-auto h-full w-full z-10 
         ${
          currentRoute?.pathname === "/" ? "rounded-t-[20px]" : ""
        }
       `} */}
			<div
				className={`relative text-white ${
					currentRoute?.pathname === "/profile" ? "bg-[#F5F5F5]" : "bg-white"
				} overflow-auto h-full w-full z-10
       `}
				style={{
					marginTop: `${topBarHeight}px`,
					paddingBottom: `${
						currentRoute?.pathname === "/explore" ||
						currentRoute?.pathname?.startsWith("/buyer-input-flow")
							? 0
							: window.bridge
								? bottomBarHeight +
									window.bridge?.adjustedContentInset.bottom -
									20
								: bottomBarHeight
					}px`,
				}}
			>
				<Outlet />
			</div>
			{isNavbarVisible && !isWebview && (
				<div
					className={`fixed z-20 h-16 ${
						window.bridge ? "bottom-bridge-inset-bottom" : "bottom-4"
					}  w-full max-w-[90vw] sm:max-w-lg -translate-x-1/2 left-1/2  bg-white rounded-full shadow-md drop-shadow-lg py-2`}
					ref={bottomBarRef}
				>
					<NavigationBar />
				</div>
			)}
			{showLogOutModal && <LogOutModal />}
			{showTopMatchesModal && <YourTopMatchesModal />}
			<ConnectBankAccountModal />
			<PaymentAssistanceInfoModal />
			<WatchVideo />
			<StartHomeShoppingModal />
			<CartModal />
			<BuyHomePlanModal />
			<FinanceIncomeModal />
			<FinanceSavingsModal />
			<FlinksModal />

			<DebtOverPage setTotalDebt={setTotalDebt} />
			<EditHouseGoalOverPage />
			<PlanBuyingHome />
			<PlanSpendHome />
			<WhenBuyHome />
			<FinancialReport />
			<AnotherDebtOverPage />
			{showGetQualifiedModal && isWebview && !filterPanelVisible && (
				<GetQualifiedModal />
			)}
			{filterPanelVisible && <FilterPanel panelOpen={filterPanelVisible} />}
		</div>
	);
};

export { MainLayout };
