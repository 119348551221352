import { useEffect, useState } from "react";
import MapDotIcon from "../../../assets/MapDotIcon.svg";
import { Map as MapboxMap, Marker } from "react-map-gl";

type LisintgDescriptionMapProps = {
	lat: number;
	lng: number;
};
const MAPBOX_TOKEN =
	"pk.eyJ1Ijoiem93biIsImEiOiJjbTkxc2dqb3cwNGJwMmpweXI1Yjd3ajh4In0.31CyQpnnjcZKyINQl9Fh5g";

const LisintgDescriptionMap = ({ lat, lng }: LisintgDescriptionMapProps) => {
	const [viewport, setViewport] = useState({
		latitude: lat,
		longitude: lng,
		zoom: 17,
	});

	useEffect(() => {
		setViewport((prev) => ({
			...prev,
			latitude: lat,
			longitude: lng,
		}));
	}, [lat, lng]);

	return (
		<div
			style={{
				width: "100%",
				height: "189px",
				borderRadius: "15px",
				outline: "none",
			}}
		>
			<MapboxMap
				mapboxAccessToken={MAPBOX_TOKEN}
				initialViewState={viewport}
				mapStyle="mapbox://styles/mapbox/light-v10"
				onMove={(evt) => setViewport(evt.viewState)}
				style={{ width: "100%", height: "100%", borderRadius: "15px" }}
			>
				<Marker longitude={lng} latitude={lat} anchor="bottom">
					<img
						src={MapDotIcon}
						alt="Listing Location"
						className="translate-y-1/2"
					/>
				</Marker>
			</MapboxMap>
		</div>
	);
};

export { LisintgDescriptionMap };
