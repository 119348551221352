import "./filterPanelStyles.css";
import { formatPrice } from "../../../utils/formatPrice";
import { useMapFiltersStore } from "../../../stores/filterMapStore";
import {
	type ChangeEvent,
	type RefObject,
	useCallback,
	useEffect,
	useRef,
} from "react";

interface PriceSliderProps {
	min: number;
	max: number;
}

interface ValueSliderProps {
	range: RefObject<HTMLDivElement>;
}

interface RangeInputProps {
	type: "min" | "max";
	min: number;
	max: number;
	value: number;
	minVal: number;
	maxVal: number;
	setPrice: (value: number) => void;
	inputRef: RefObject<HTMLInputElement>;
}

interface ValuesDisplayProps {
	minVal: number;
	maxVal: number;
}

const ValueSlider = ({ range }: ValueSliderProps) => {
	return (
		<div className="relative w-full">
			<div className="slider__track" />
			<div ref={range} className="slider__range absolute" />
		</div>
	);
};

const RangeInput = ({
	type,
	min,
	max,
	value,
	setPrice,
	minVal,
	maxVal,
	inputRef,
}: RangeInputProps) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputValue = +event.target.value;
		const roundedValue = Math.round(inputValue / 25000) * 25000;

		let updatedValue = roundedValue;

		if (type === "min") {
			updatedValue = Math.min(roundedValue, maxVal - 25000);
			updatedValue = Math.max(updatedValue, min);
		} else {
			updatedValue = Math.max(roundedValue, minVal + 25000);
			updatedValue = Math.min(updatedValue, max);
		}

		setPrice(updatedValue);
	};

	return (
		<input
			type="range"
			min={min}
			max={max}
			value={value}
			ref={inputRef}
			onChange={handleChange}
			className={`w-full thumb ${
				type === "min" ? "thumb--zindex-3" : "thumb--zindex-4"
			}`}
			style={{
				zIndex:
					value && (type === "min" ? value > max - 100 : value < min + 100)
						? 5
						: 3,
			}}
		/>
	);
};

const ValuesDisplay = ({ minVal, maxVal }: ValuesDisplayProps) => {
	const formattedMinVal =
		minVal !== undefined && minVal !== null ? `$${formatPrice(minVal)}` : "";
	const formattedMaxVal =
		maxVal !== undefined && maxVal !== null ? `$${formatPrice(maxVal)}` : "";

	return (
		<div className="value-display-container w-full flex justify-center">
			<div className="value-display">
				<div className="value-display-label" id="min-val-display">
					Minimum
				</div>
				<input
					className="value-display-value"
					id="min-val-display"
					type="text"
					readOnly={true}
					value={formattedMinVal}
				/>
			</div>

			<hr className="w-3/12 border-t-2 border-gray-400" />

			<div className="value-display">
				<div className="value-display-label" id="grid-last-name">
					Maximum
				</div>
				<input
					className="value-display-value"
					id="grid-last-name"
					type="text"
					readOnly={true}
					value={formattedMaxVal}
				/>
			</div>
		</div>
	);
};

const PriceSlider = ({ min, max }: PriceSliderProps) => {
	const { setMinPrice, setMaxPrice, minPrice, maxPrice } = useMapFiltersStore();
	const minValRef = useRef<HTMLInputElement>(null);
	const maxValRef = useRef<HTMLInputElement>(null);
	const range = useRef<HTMLDivElement>(null);

	const minPriceValue =
		minPrice !== undefined && minPrice !== null ? minPrice : min;
	const maxPriceValue =
		maxPrice !== undefined && maxPrice !== null ? maxPrice : max;

	const getPercent = useCallback(
		(value: number) => Math.round(((value - min) / (max - min)) * 100),
		[min, max],
	);

	useEffect(() => {
		if (minValRef.current && maxValRef.current && range.current) {
			const minPercent = getPercent(minPriceValue);
			const maxPercent = getPercent(maxPriceValue);

			if (range.current.style) {
				range.current.style.left = `${minPercent}%`;
				range.current.style.width = `${Math.max(0, maxPercent - minPercent)}%`;
			}
		}
	}, [minPriceValue, maxPriceValue, getPercent]);

	return (
		<div className="flex flex-col items-center w-full">
			<div className="relative pb-5 w-full max-w-lg mx-auto">
				<RangeInput
					type="min"
					min={min}
					max={max}
					value={minPriceValue}
					setPrice={setMinPrice}
					minVal={minPriceValue}
					maxVal={maxPriceValue}
					inputRef={minValRef}
				/>
				<RangeInput
					type="max"
					min={min}
					max={max}
					value={maxPriceValue}
					setPrice={setMaxPrice}
					minVal={minPriceValue}
					maxVal={maxPriceValue}
					inputRef={maxValRef}
				/>
				<ValueSlider range={range} />
			</div>
			<ValuesDisplay minVal={minPriceValue} maxVal={maxPriceValue} />
		</div>
	);
};

export { PriceSlider };
